<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <v-col cols="10" sm="8" class="pa-0 mt-10 pt-10 mx-auto" v-else>
      <div class="font-bold text-2xl sm:text-4xl mt-10 pt-10">
        {{ item.title }}
      </div>
      <div class="text-sm py-4">
        {{ item.lastUpdate }} | {{ item.category }}
      </div>
      <img
        :src="baseUrlImage + item.image.file_path"
        @error="$event.target.src = 'https://admin.bpdlh.id/assets/empty.png'"
        width="100%"
        class="rounded-md mt-8 img-detail" />
      <div class="white grid justify-items-center px-0 pt-5">
        <v-col cols="12" class="pa-0">
          <div class="font-bold text-base sm:text-xl my-5 text-left">
            {{ item.title }}
          </div>
          <div class="text-left mb-10" v-html="item.desc"></div>
        </v-col>
      </div>
    </v-col>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoad: false,
      item: {},
      lastUpdated: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
    };
  },
  watch: {
    page() {
      this.getData();
    },
    language() {
      this.getData();
    },
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        page: this.page,
        type: "berita",
      };
      await axios
        .get(this.baseUrl + "/content" + "/" + this.id, { params: param })
        .then((response) => {
          this.item = response.data.data;
          this.lastUpdated = response.data.data.lastUpdate;
        })
        .catch((error) => {
          if (error.response.data.code === 404) {
            this.$router.push({ name: "NotFound" });
          } else {
            this.isLoad = false;
            Swal.fire("Failed!", error.response.data.message, "error");
          }
        })
        .finally(() => (this.isLoad = false));
    },
  },
};
</script>
